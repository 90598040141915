<template>
  <v-dialog max-width="500" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon small> <v-icon small>$eye</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("discounts.resume") }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-data-table :headers="headers"></v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ResumeDiscounts",
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: this.$t("first_name"),
          align: "center",
          sortable: false,
          value: "user.first_name",
        },
        {
          text: this.$t("last_name"),
          align: "center",
          sortable: false,
          value: "last_name",
        },
        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
};
</script>